import React, { useContext, useEffect, useState } from 'react'
import * as API from '../../util/API'
import * as GTM from '../../util/GTM'
import { ReserveNumberContext } from './ReserveNumberContext'
import { CartItem, PhoneNumberData } from '../../types'
import LeadContext from '../../LeadContext'
import { convertFromE164toPDC } from 'phone-numbers'
import { useHistory } from 'react-router-dom'
import { getCookieValue } from '../../util/Cookies'
import LoaderFull from 'loader-full'

interface ReserveNumberContextProviderProps {
    children: JSX.Element
}

/**
 * This context provides components throughout the pick-a-number step with access to state related to
 * reserving a number in order to purchase it.
 * This includes the status of the request, functions to reserve a number, and in the future numbers that
 * were found to be already taken.
 */
const ReserveNumberContextProvider = ({ children } : ReserveNumberContextProviderProps) : JSX.Element => {
    const [isReservingNumber, setIsReservingNumber] = useState<boolean>(false)
    const [choosenPhoneNumber, setChoosenPhoneNumber] = useState<PhoneNumberData | { phone_number: string } | null>(null) /* eslint-disable-line @typescript-eslint/naming-convention */
    const [numbersTaken, setNumbersTaken] = useState<PhoneNumberData[]>([])
    const [error, setError] = useState<Error | null>(null)

    // NOTE: number query string param should be in E164 format eg. "+18004382429"
    const [autoSelectedNumber, setAutoSelectedNumber] = useState<null | string>(new URLSearchParams(window.location.search).get('number'))

    const { addToCart } = useContext(LeadContext)

    const history = useHistory()

    const requestNumberReservation = async (params: PhoneNumberData | { phone_number: string }) => { /* eslint-disable-line @typescript-eslint/naming-convention */
        setChoosenPhoneNumber(params)
        setIsReservingNumber(true)

        let cartItem: CartItem | null = null
        try {
            const response = await API.reserveNumber(params)
            cartItem = response.data
            if (cartItem) {
                addToCart(cartItem)
            }
        } catch (error) {
            if (error.message.includes('got to that number first')) {
                GTM.numberAlreadyTaken(params.phone_number)
                setNumbersTaken([...numbersTaken, params])
            } else {
                GTM.reserveNumberError(error)
            }
            setError(error)
        }

        setIsReservingNumber(false)

        return cartItem
    }

    const reserveNumber = async (params: PhoneNumberData) => requestNumberReservation(params)

    const autoReserveNumber = async (number: string) => {
        console.debug(`auto reserving number: ${number}`)
        const cartItem = await requestNumberReservation({
            phone_number: convertFromE164toPDC(number) /* eslint-disable-line @typescript-eslint/naming-convention */
        })
        setAutoSelectedNumber(null)
        if (cartItem) {
            history.push(`/checkout?session_token=${getCookieValue('pdc_lead_session_token')}`)
        }
    }

    const clearReservationError = () => {
        setError(null)
    }

    useEffect(() => {
        if (autoSelectedNumber) {
            autoReserveNumber(autoSelectedNumber)
        }
    }, [autoSelectedNumber])

    if (autoSelectedNumber) {
        // We need to wait for the auto-selected number reservation to be completed (succeeded or failed),
        // if we attempt to let the rest of sign-up load this could result in issues like the order summary being loaded (and inaccurately not reflecting this newly reserved number)
        return (
            <LoaderFull />
        )
    }

    return (
        <ReserveNumberContext.Provider value={{
            reserveNumber,
            isReservingNumber,
            choosenPhoneNumber,
            error,
            clearReservationError,
            numbersTaken
        }}>
            {children}
        </ReserveNumberContext.Provider>
    )
}

export default ReserveNumberContextProvider
