import axios, { AxiosError } from 'axios'
import { PhoneNumberData, GetSummaryStatusAPIResponse, Lead } from '../types'
import { convertNumberToE164 } from 'phone-numbers'
import { Bill } from '../types'
import {
    getCookieValue,
    setCookie,
    removeCookie
} from './Cookies'
import { LeadParams } from '../types'
import * as WebEyez from './WebEyez'
import { parsePhoneNumber } from 'libphonenumber-js'
import { ValidationError } from './Validations'

const sessionTokenCookieName = 'pdc_lead_session_token'

interface CreateAccountStatusReport {
    status: 'not_started' | 'in_progress' | 'complete' | 'failed';
    create_account_errors: CreateAccountErrorJSON[] // eslint-disable-line @typescript-eslint/naming-convention
}

interface CreateAccountErrorJSON {
    error_type: string; // eslint-disable-line @typescript-eslint/naming-convention
    internal_details: string; // eslint-disable-line @typescript-eslint/naming-convention
    user_facing_description: string; // eslint-disable-line @typescript-eslint/naming-convention
    error_code: number | null; // eslint-disable-line @typescript-eslint/naming-convention
}

/**
 * Represents an error that occurs during the account creation process.
 * This error class will contain all the details specific to what create-account-async API
 */
export class CreateAccountError extends Error {
    createAccountErrors: CreateAccountErrorJSON[];

    /**
     * @param {CreateAccountStatusReport} statusRepport the status report received from get-create-account-status API
     */
    constructor (statusRepport: CreateAccountStatusReport) {
        super(statusRepport?.create_account_errors[0]?.user_facing_description || 'An error occured while setting up your account, your card has not been charged.')
        this.createAccountErrors = statusRepport.create_account_errors
    }
}

/**
 *
 */
export class CartEmptyError extends Error {
}

/**
 * Private helper function that handles common tasks like handling errors
 * and HttpRedirectRequests across all API requests.
 *
 * @param url url to send the request to
 * @param params request body parameters
 */
const callAPI = (url : string, params : unknown, method = 'post') => {
    const allParams = {
        ...params,
        session_token: getCookieValue(sessionTokenCookieName) /* eslint-disable-line @typescript-eslint/naming-convention */
    }

    return axios[method](url, allParams).then(response => {
        const { data } = response

        const handleError = (error: Error) => {
            WebEyez.reportAPICall({
                apiURL: url,
                requestPayload: params,
                responseText: `${JSON.stringify(data)}`,
                textStatus: response.statusText,
                statusCode: response.status,
                isSuccess: false,
                errorMessage: error.message
            })

            throw error
        }

        if (data.errorType === 'HttpRedirectRequest') {
            window.location = data.errorMessage
            return
        }

        let error = null
        if (typeof data.errors === 'object') { // ValidationErrors are serialized from the backend like this
            // TODO: if the server sends error info in this format:
            // { errors: { field_name: error_for_that_field } }
            // This can be used if needed in the future to extract the name(s) of the fields associated with the error
            error = new ValidationError(Object.values(data.errors)[0], data.error_code)
        } else {
            const errorMessage = data.error?.errors?.payment_decline || data.errorMessage || data.error?.message || data.error
            if (errorMessage) {
                error = new Error(errorMessage)
            }
        }

        if (error) {
            handleError(error)
        }

        WebEyez.reportAPICall({
            apiURL: url,
            requestPayload: params,
            responseText: `${JSON.stringify(data)}`,
            textStatus: response.statusText,
            statusCode: response.status,
            isSuccess: true
        })

        return response
    })
}

/**
 * Fetches lead data representing the user (as a prospective customer or lead), this will register a new lead if necessary,
 * and set the lead session token cookie so the lead can be referenced in the future.
 */
export const initLead = async (): Promise<null | LeadParams> => {
    const params = new URLSearchParams(window.location.search) // pass on any query params like pro_user_qty=X
    params.set('format', 'json')
    const sessionToken = getCookieValue(sessionTokenCookieName) || params.get('session_token')

    params.set('sign_up_version', 'enhanced_sign_up')

    if (sessionToken) {
        params.set('lead_session_token', sessionToken) // IMPORTANT: elsewise server will create another/new lead
    }

    if (!sessionToken && !['basic_user_qty', 'plus_user_qty', 'pro_user_qty', 'package'].some(p => params.has(p))) {
        // If you dont have a session token cookie, (expired or never set), and you haven't specified by query params the package/users
        // we need to send you to the beginning of the process (the pricing page) so you can select package/users
        console.log('Redirecting user to pricing page (user must begin by selecting package/users)')
        redirectToPricingPage()
        return null
    }

    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up?${params.toString()}`

    return callAPI(requestUrl, {}, 'get').then(response => {
        setCookie(sessionTokenCookieName, response.data.session_token, { expires: 30 }) // 30 days
        return response.data
    })
}

/**
 * Redirects the user back to the correct pricing page
 */
export const redirectToPricingPage = (): void => {
    const isClassicSignUp = getCookieValue('is_classic_signup')?.toLowerCase() === 'true'

    const url = isClassicSignUp ? process.env.REACT_APP_CLASSIC_PRICING_PAGE_URL : process.env.REACT_APP_USERS_PRICING_PAGE_URL

    const pricingPageURL = new URL(url)
    const searchParams = new URLSearchParams({ destination_url: window.location.host }) /* eslint-disable-line @typescript-eslint/naming-convention */
    pricingPageURL.search = searchParams.toString()

    console.log(`Redirecting user to pricing page: ${pricingPageURL}`)
    window.location = pricingPageURL?.toString()
}

/**
 * Fetches a list of phone numbers for purchase, that contain some sequence of letters
 */
export const getNumbersContainingText = async (text: string) => {
    const requestUrl = `${process.env.REACT_APP_SERVICES_URL}/numbers/search-available-numbers`
    return callAPI(requestUrl, {
        filter_by: { /* eslint-disable-line @typescript-eslint/naming-convention */
            contains: text,
            local: true,
            toll_free: true, /* eslint-disable-line @typescript-eslint/naming-convention */
            vanity: true
        },
        limit: 9,
        order_by: '', /* eslint-disable-line @typescript-eslint/naming-convention */
        vanity: true
    })
}

interface ReserveNumerPayload {
    phone_number: string; /* eslint-disable-line @typescript-eslint/naming-convention */
    did: string;
    number_search_id?: string; /* eslint-disable-line @typescript-eslint/naming-convention */
    vanity_number?: string; /* eslint-disable-line @typescript-eslint/naming-convention */
}

/**
 * Reserves a phone number, adding it to the user's/lead's cart
 * In some cases this may fail if the number has already recently been taken
 */
export const reserveNumber = async (params: PhoneNumberData | { phone_number: string }) => { /* eslint-disable-line @typescript-eslint/naming-convention */
    const payload: ReserveNumerPayload = {
        phone_number: params.phone_number, // eslint-disable-line @typescript-eslint/naming-convention
        did: convertNumberToE164(params.phone_number)
    }

    if ('number_search_result_id' in params) {
        const phoneNumberData = params as PhoneNumberData
        payload.number_search_id = phoneNumberData.number_search_result_id /* eslint-disable-line @typescript-eslint/naming-convention */
        payload.vanity_number = phoneNumberData.vanity_number // eslint-disable-line @typescript-eslint/naming-convention
    }

    // NOTE: we have a separate API for reserving vanity numbers (custom numbers containing strings)
    // and a separate API used by the toll-free and local pickers, in the future we can maybe combine these into one API
    const url = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/${payload.vanity_number ? 'find-vanity-number/reserve-vanity-number' : 'pick-a-number/postback'}`

    return callAPI(url, payload)
}

/**
 * Helper function that uses getSummaryAsync + getSummaryStatus
 * to poll for the summary untill it is available.
 *
 * This abstracts the polling process to get the bill summary as one simple async function.
 */
export const getSummary = async (couponCode : string, paymentPeriod: number): Promise<Bill> => {
    const response = await getSummaryAsync(couponCode, paymentPeriod)
    const url = response?.data?.url_to_poll

    const pollInterval = 1000

    const waitThenCheck = async () => new Promise(resolve => setTimeout(resolve, pollInterval))

    // Keep checking status untill bill is ready
    while (true) {
        const statusResponse = await getSummaryStatus(url)

        const status = statusResponse?.data?.status
        console.debug(`Checking order summary, received status: ${status}`)

        switch (status) {
                case 'not_started':
                case 'in_progress':
                    await waitThenCheck()
                    break
                case 'completed':
                    return statusResponse.data.bill
                case 'failed':
                    if (statusResponse?.data.error === 'cart_empty') {
                        throw new CartEmptyError('Your number reservation expired, please pick a number.')
                    } else {
                        throw new Error('A problem occured while loading your order summary')
                    }
                default:
                    console.error(`get-summary-status received an invalid status: "${status}"`)
                    await waitThenCheck()
        }
    }
}

/**
 * Makes a request to get-summary-async, which asynchronously invokes the get-summary-details lambda.
 * This starts the process of computing the bill including the list of charges (including recurring charges) the user will have to pay
 */
export const getSummaryAsync = async (couponCode : string, paymentPeriod: number) => {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/view-summary-and-terms/get-summary-async`
    return callAPI(requestUrl, {
        coupon_code: couponCode, /* eslint-disable-line @typescript-eslint/naming-convention */
        payment_period: paymentPeriod /* eslint-disable-line @typescript-eslint/naming-convention */
    })
}

/**
 * Checks on the status of computing the bill, that was started by get-summary-async
 */
export const getSummaryStatus = async (pollURL : string) : Promise<GetSummaryStatusAPIResponse> => {
    return axios.get(pollURL)
}

/**
 * Fetches a list of toll-free numbers
 */
export async function getTollFreeNumbers () {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/pick-a-number`
    return callAPI(requestUrl, {
        format: 'json',
        number_type: 'toll_free', /* eslint-disable-line @typescript-eslint/naming-convention */
        limit: 9
    })
}

/**
 * Reserves a temporary number for the lead (or retrieves it if one is already reserved)
 */
export async function getTransferNumber () {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/transfer-my-number`
    return callAPI(requestUrl, {
        format: 'json'
    })
}

/**
 * Returns a list of all area codes that we have numbers for
 */
export const getAreaCodeList = async () : Promise<string[]> => {
    // NOTE:  this API has a hardcoded list of area codes, that originated from services.phone.com/numbers/search-area-codes
    // for now use this for consistency with existing sign-up, later consider directly using services.phone.com
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/select-area-code`
    return callAPI(requestUrl, {
        format: 'json'
    }).then(response => response?.data.area_codes)
}

/**
 * Fetches numbers local to the user, by detecting the user's approximated location using their IP address
 */
export async function getLocalNumbers () {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/pick-a-number`
    return callAPI(requestUrl, {
        format: 'json',
        number_type: 'local', /* eslint-disable-line @typescript-eslint/naming-convention */
        close_to_me: true, /* eslint-disable-line @typescript-eslint/naming-convention */
        limit: 9
    })
}

/**
 * Fetches numbers by a specific area code
 */
export async function getNumbersByAreaCode (areaCode: string) {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/pick-a-number`
    return callAPI(requestUrl, {
        format: 'json',
        number_type: 'local', /* eslint-disable-line @typescript-eslint/naming-convention */
        area_code: areaCode, /* eslint-disable-line @typescript-eslint/naming-convention */
        limit: 8
    })
}

/**
 * Checks if a zip code (or postal code) is valid.
 */
export async function checkZipCode (zipCode: string) {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/enter-zip-code/check-zip-code` // TODO create API dedicated to updating lead
    return callAPI(requestUrl, {
        zipcode: zipCode
    })
}

/**
 * Saves various lead information including things like name, email, business address, etc.
 */
export async function saveLead (lead: Lead, mfaReturnURL?: string | null) {
    lead.gcid = getCookieValue('_ga')

    const params = {
        ...lead,
        format: 'json'
    }

    if (lead.personal_phone_number) {
        // When saving personal_phone_number send it in E.164 format ("+1XXXXXXXXXX")
        params.personal_phone_number = parsePhoneNumber(lead.personal_phone_number, 'US').number /* eslint-disable-line @typescript-eslint/naming-convention */
    }

    if (mfaReturnURL) {
        params.mfa_return_url = mfaReturnURL // This param when present will instruct the backend to initiate the setup of multi-factor authentication (MFA)
    }

    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/enter-zip-code` // TODO create API dedicated to updating lead
    return callAPI(requestUrl, params)
}

/**
 * Saves the token received locally from the google recaptcha human verification library on the backend,
 * and verifies the authenticity of the token.
 */
export async function verifyRecaptcha (recaptchaResponseToken : string) {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/create-credentials/verify-recaptcha`
    return callAPI(requestUrl, {
        g_recaptcha_response_token: recaptchaResponseToken /* eslint-disable-line @typescript-eslint/naming-convention */
    })
}

/**
 * Saves the Stripe payment token on the backend, so the payment information (card details) can then be used
 * to charge the customer
 */
export async function createPaymentToken (stripePaymentToken : string) {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/enter-credit-card/create-payment-token`
    return callAPI(requestUrl, {
        stripe_payment_token: stripePaymentToken /* eslint-disable-line @typescript-eslint/naming-convention */
    })
}

/**
 * Starts the asynchronous process of creating the user's account based on the previous submitted lead data.
 *
 * @returns
 */
export async function createAccountAsync () {
    const requestUrl = `https://${process.env.REACT_APP_API_HOST}/sign-up/steps/login/create-account-async`
    return callAPI(requestUrl, {})
}

/**
 * Synchronous helper function that starts the account creation process, then internally polls untill it is completed.
 */
export async function createAccount () {
    const response = await createAccountAsync()
    const { token, url_to_poll } = (response?.data || {}) /* eslint-disable-line @typescript-eslint/naming-convention */

    const pollInterval = 1000

    // Keep polling untill account creation status is completed for failed
    while (true) {
        const response = await callAPI(url_to_poll, { token }).catch((error: AxiosError) => {
            if (!error.status && error.message.includes('Network Error')) {
                throw new Error('Network Error: please check your internet connection. Your order has still been submitted and will continue processing')
            }
        })
        const statusReport = response?.data
        const status = statusReport?.status
        console.debug(`Checking account creation process, received status: ${status}`)

        switch (status) {
                case 'complete':
                    removeCookie('pdc_lead_session_token')
                    return statusReport.created_account_details
                case 'failed':
                    throw new CreateAccountError(statusReport)
                case 'not_started':
                case 'in_progress':
                    break
                default:
                    console.warn(`Invalid status received from API ${url_to_poll}: status: ${status}`)
        }

        await new Promise(resolve => setTimeout(resolve, pollInterval))
    }
}
