import React, { useContext } from 'react'
import { ReserveNumberContext } from './ReserveNumberContext'
import Alert, { Color as AlertColor } from '../../design-components/Alert'

/**
 * UI to display the ongoing status of the number reservation process,
 * including any error(s) in reserving a number and whether the request
 * is still pending.
 */
const ReserveNumberStatus = () : JSX.Element | null => {
    const { error, isReservingNumber } = useContext(ReserveNumberContext)

    if (isReservingNumber) {
        return (
            <Alert
                showIcon={false}
                color={AlertColor.INFO}
                content={<strong>Reserving your number...</strong>}
            />
        )
    }

    if (error) {
        return (
            <Alert
                showIcon={true}
                color={AlertColor.ERROR}
                content={error.message}
            />
        )
    }

    return null
}

export default ReserveNumberStatus
