import React from 'react'
import Item from './Item'

interface TotalProps {
    total: number,
    totalRecurring: number,
    billingCountryCode: string
}

/**
 * Total, as part of the OrderSummary
 */
const Total = ({ total, totalRecurring, billingCountryCode } : TotalProps) : JSX.Element => {
    const unit = billingCountryCode === 'US' ? '$' : 'US $'
    return (
        <Item label={total === totalRecurring ? 'Total:' : 'Total due now:'} cost={`${total}`} unit={unit} />
    )
}

export default Total
